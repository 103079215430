import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";


export interface Notification{
    _id: string;
    news_id: string;
    news: string;
    author: string;
    imgUrl: string;
}

export interface NotificationInfo{
    notifications: Notification[];
    notification: Notification;
    count: number;
}

@Module
export default class NotificationModule extends VuexModule implements NotificationInfo{
    notifications:Notification[] = [];
    notification = {} as Notification;
    count = 0
   /**
   * Get current user object
   * @returns Categories
   */
  get alNotification(): Notification[] {
    return this.notifications;
  }

    /**
   * Get current user object
   * @returns one category
   */
  get notificationCount(): number {
    return this.count;
  }

  @Mutation
  [Mutations.SET_NOTIFICATIONS](notifications: Notification[]){
    this.notifications = notifications
  }

  @Mutation
  [Mutations.SET_NOTIFICATION](notification: Notification){
    this.notifications = [notification, ...this.notifications]
    this.count = this.count + 1
    console.log(this.count, 'count');
    
  }

  @Mutation
  [Mutations.SET_NOTIFICATION_COUNT](count:number){
    this.count = count
  }

  @Mutation
  [Mutations.REMOVE_NOTIFICATION](id:string){
    console.log(id,'payload');
    
    const index = this.notifications.findIndex(item => item._id == id);
    console.log(index);
    if(index > -1){
      this.count --
      this.notifications.slice(index, 1)
    }
  }



  @Action
  [Actions.ADD_NOTIFICATION](payload){
    this.context.commit(Mutations.SET_NOTIFICATION, payload.data);
  }

  @Action
  [Actions.ALL_NOTIFICATIONS](payload){
    return ApiService.query("notification/all", payload)
    .then(({ data }) => {
        data.notification = data.notifications.map(item =>{
            return {_id: item._id, news_id: item.news._id, news: item.news?.title, author: item.from?.name, imgUrl: item.news?.img}
        })
    
        this.context.commit(Mutations.SET_NOTIFICATION_COUNT, data.count);
        this.context.commit(Mutations.SET_NOTIFICATIONS, data.notification);
      })
  }
}