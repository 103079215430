// import { Language } from './LanguageModule';
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Language {
    _id: string;
    user: string;
    name: string;
    icon: string;
    code: string;
    def: string;
    status: number
}

export interface LanguageInfo{
    languages: Language[];
    language: Language;
}


@Module
export default class LanguageModule extends VuexModule implements LanguageInfo{
  languages: Language[] = [];
    language = {} as Language;
    

    /**
   * Get current user object
   * @returns Categories
   */
  get langs(): Language[] {
    return this.languages;
  }

    /**
   * Get current user object
   * @returns one category
   */
  get oneLanguage(): Language {
    return this.language;
  }

  @Mutation
  [Mutations.SET_LANGUAGES](languages){
    this.languages = languages
  }

  @Mutation
  [Mutations.SET_LANGUAGE](language: Language){
    this.languages = [...this.languages, language]
    
  }

  @Mutation
  [Mutations.SET_EDIT_LANGUAGE](language: Language){
    const index = this.languages.findIndex((item: Language)=> item._id == language._id)
    if(index > -1){
      this.languages[index] = language
    }
    
  }

  @Mutation
  [Mutations.SET_DEL_LANGUAGE](id: string){
    const index = this.languages.findIndex((item: Language)=> item._id == id)
    if(index > -1){
      this.languages.splice(index, 1)
    }
    
  }

  @Action
  [Actions.ALL_LANGUAGES](){
    return ApiService.get("language")
    .then(({ data }) => {
       this.context.commit(Mutations.SET_LANGUAGES, data.language)
       this.context.commit("setCount", data.count)
      })
  }


  @Action
  [Actions.ADD_LANGUAGE](payload){
    return ApiService.post("/language", payload.value)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_LANGUAGE, data)
      })
  }

  @Action
  [Actions.EDIT_LANGUAGE](payload){
    return ApiService.put("/language", payload.value)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_LANGUAGE, data)
      })
  }

  @Action
  [Actions.CHANGE_DEF_LANG](payload){
    return ApiService.get("/language/def/"+ payload)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_LANGUAGES, data)
      })
  }

  @Action
  [Actions.DEL_LANGUAGE](payload){
    return ApiService.delete("/language/"+ payload)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_DEL_LANGUAGE, data)
      })
  }
}