import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Category {
    _id: string;
    translate?: string;
    title: string;
    text: string;
    icon: string;
    meta_title: string;
    meta_keyword: string;
    meta_script: string;
    meta_desc: string;
    slug: string;
    status: number;
}

export interface CategoryInfo{
    categories: Category[];
    category: Category;
}


@Module
export default class CategoryModule extends VuexModule implements CategoryInfo{
    categories = [];
    category = {} as Category;
    

    /**
   * Get current user object
   * @returns Categories
   */
  get alCategories(): Category[] {
    return this.categories;
  }

    /**
   * Get current user object
   * @returns one category
   */
  get oneCategory(): Category {
    return this.category;
  }

  @Mutation
  [Mutations.SET_CATEGORIES](categories){
    this.categories = categories
  }

  @Mutation
  [Mutations.SET_CATEGORY](category){
    this.category = category
  }

  @Mutation
  [Mutations.SET_DEL_CATEGORY](id: string){
    const index = this.categories.findIndex((item: Category)=> item._id == id)
    if(index > -1){
      this.categories.splice(index, 1)
    }
    
  }

  @Action
  [Actions.ALL_CATEGORIES](pay){
    return ApiService.query("category", pay)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_CATEGORIES, data.category)
       this.context.commit('setCount', data.count)
      })
  }


  @Action
  [Actions.ADD_CATEGORY](payload){

    return ApiService.post("/category", payload.value)
    .then(({ data }) => {
       console.log(data, 'new categoriya')
      //  this.context.commit(Mutations.SET_CATEGORIES, data.category)
      })
  }


  @Action
  [Actions.EDIT_CATEGORY](payload){
    console.log(payload, 'edit payload');
    
    return ApiService.put("/category/", payload.value)
    .then(({ data }) => {
       
      //  this.context.commit(Mutations.SET_CATEGORY, data)
      })
  }


  @Action
  [Actions.DELETE_CATEGORY](payload){
    return ApiService.delete("/category/"+ payload)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_DEL_CATEGORY, data)
      })
  }
}