// import { Language } from './LanguageModule';
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
    _id: string;
    name: string;
    subcategory: string;
}

export interface UserInfo{
    users: User[];
    user: User;
}


@Module
export default class UsersModule extends VuexModule implements UserInfo{
    users: User[] = [];
    user = {} as User;
    
    /**
   * Get current user object
   * @returns Categories
   */
  get alUsers(): User[] {
    return this.users;
  }

    /**
   * Get current user object
   * @returns one category
   */
  get oneUser(): User {
    return this.user;
  }

  @Mutation
  [Mutations.SET_USERS](users: User[]){
    this.users = users
  }

  @Mutation

  [Mutations.SET_ONE_USER](users : User){
    this.users = [...this.users, users]
  }

  @Mutation
  [Mutations.SET_EDIT_USER](user: User){
    const index = this.users.findIndex((item: User)=> item._id == user._id)
    if(index > -1){
      this.users[index] = user
    } 
  }

//   @Mutation
//   [Mutations.SET_DEL_TAG](id: string){
//     const index = this.tags.findIndex((item: Tag)=> item._id == id)
//     if(index > -1){
//       this.tags.splice(index, 1)
//     }
    
//   }

  @Action
  [Actions.ALL_USERS](pay){
    return ApiService.query("user", pay)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_USERS, data.users);
       this.context.commit('setCount', data.count)
      })
  }


  @Action
  [Actions.CHANGE_USER_ROLE](pay){
    return ApiService.put("user/role/" + pay.item, pay.role)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_USER, data.user)
      })
  }

  @Action
  [Actions.CHANGE_USER_STATUS](pay){
    return ApiService.get("user/status/"+pay.item+"/"+pay.status)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_USER, data)
      })
  }

  @Action
  [Actions.ADD_USER](payload){
    return ApiService.post("/tag", payload.value)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_TAG, data)
      })
  }

  // @Action
  // [Actions.EDIT_TAG](payload){
  //   return ApiService.put("/tag", payload.value)
  //   .then(({ data }) => {
  //     console.log(data, 'returned data')
  //      this.context.commit(Mutations.SET_EDIT_TAG, data)
  //     })
  // }

  // @Action
  // [Actions.DEL_TAG](payload){
  //   return ApiService.delete("/tag/"+ payload)
  //   .then(({ data }) => {
  //      this.context.commit(Mutations.SET_DEL_TAG, data)
  //     })
  // }
}