
import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import CategoryModule from "@/store/modules/CategoryModule";
import SubcategoryModule from "@/store/modules/SubcategoryModule";
import LanguageModule from "@/store/modules/LanguageModule";
import TagsModule from "@/store/modules/TagsModule";
import SocialsModule from "@/store/modules/SocialModule";
import ParametrsModule from "@/store/modules/ParametrModule";
import TranslateModule from "@/store/modules/TranslateModule";
import UserModule from "@/store/modules/UserModule";
import NewsModule from "@/store/modules/NewsModule";
import TypeesModule from "@/store/modules/TypesModule";
import NotificationModule from "@/store/modules/NotificationModule";
import SectionModule from "@/store/modules/SectionModule";
import QuestionModule from "@/store/modules/QuestionModule";



config.rawError = true;

const store = createStore({
  state () {
    return {
      count: 0
    }
  },
  getters:{
    getCount(state){
      return state.count
    }
  },
  mutations: {
    setCount (state, payload) {
      state.count = payload
    }
  },     
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    CategoryModule,
    SubcategoryModule,
    LanguageModule,
    TagsModule,
    SocialsModule,
    ParametrsModule,
    TranslateModule,
    UserModule,
    NewsModule,
    TypeesModule,
    NotificationModule,
    SectionModule, QuestionModule
  },
});

export default store;
