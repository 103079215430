// import { Language } from './LanguageModule';
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Answer{
    index: number,
    answer: string,
    count: number
}

export interface Question {
    _id: string;
    question: string;
    answers: Answer[]
}

export interface QuestionInfo{
    questions: Question[];
    question: Question;
}


@Module
export default class QuestionModule extends VuexModule implements QuestionInfo{
    questions: Question[] = [];
    question = {} as Question;
    
    /**
   * Get current user object
   * @returns Categories
   */
  get alQuestions(): Question[] {
    return this.questions;
  }

    /**
   * Get current user object
   * @returns one category
   */
  get oneQuestion(): Question {
    return this.question;
  }

  @Mutation
  [Mutations.SET_QUESTIONS](questions: Question[]){
    this.questions = questions
  }

  @Mutation
  [Mutations.SET_QUESTION](question : Question){
    this.questions = [...this.questions, question]
  }

  @Mutation
  [Mutations.SET_EDIT_QUESTION](question: Question){
    const index = this.questions.findIndex((item: Question)=> item._id == question._id)
    if(index > -1){
      this.questions[index] = question
    }
    
  }

//   @Mutation
//   [Mutations.SET_DEL_SOCIAL](id: string){
//     const index = this.socials.findIndex((item: Social)=> item._id == id)
//     if(index > -1){
//       this.socials.splice(index, 1)
//     }
    
//   }

  @Action
  [Actions.ALL_QUESTIONS](pay){
    console.log(pay)
    return ApiService.query("questionnaire", pay)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_QUESTIONS, data.questionnaire)
       this.context.commit("setCount", data.count)
      })
  }


  @Action
  [Actions.ADD_QUESTION](payload){
    return ApiService.post("/questionnaire", payload.value)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_QUESTION, data)
      })
  }

  @Action
  [Actions.CHANGE_QUESTION_STATUS](pay){
    return ApiService.get("questionnaire/status/"+pay.item+"/"+pay.status)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_QUESTION, data)
      })
  }

  @Action
  [Actions.EDIT_QUESTION](payload){
    return ApiService.put("/questionnaire", payload.value)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_QUESTION, data)
    })
  }

//   @Action
//   [Actions.DEL_SOCIAL](payload){
//     return ApiService.delete("/social/"+ payload)
//     .then(({ data }) => {
//        this.context.commit(Mutations.SET_DEL_SOCIAL, data)
//       })
//   }
}