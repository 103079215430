import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Translate {
    _id: string;
    title: string;
    text: string;
    type: string;
    def: string;
    language: string;
    meta_title: string;
    meta_keyword: string;
    meta_script: string;
    meta_desc: string;
    slug: string;
}

export interface TranslateInfo{
    translates: Translate[];
    translate: Translate;
}


@Module
export default class TranslateModule extends VuexModule implements TranslateInfo{
    translates:Translate[] = [];
    translate = {} as Translate;
    

    /**
   * Get current user object
   * @returns Categories
   */
  get alTranslates(): Translate[] {
    return this.translates;
  }

    /**
   * Get current user object
   * @returns one category
   */
  get oneTranslate(): Translate {
    return this.translate;
  }

  @Mutation
  [Mutations.SET_TRANSLATES](translates){
    this.translates = translates
  }

  @Mutation
  [Mutations.SET_TRANSLATE](translate){
    this.translates = [...this.translates, translate]
  }

  @Action
  [Actions.ALL_TRANSLATES](pay){
    return ApiService.query("translate", pay)
    .then(({ data }) => {
        console.log(data);
        
       this.context.commit(Mutations.SET_TRANSLATES, data.translate)
      })
  }


  @Action
  [Actions.ADD_TRANSLATE](payload){
    return ApiService.post("/translate", payload.value)
    .then(({ data }) => {
       console.log(data, 'new TR')
       this.context.commit(Mutations.SET_TRANSLATE, data)
      })
  }
}