
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const firebaseConfig = {
      apiKey: "AIzaSyBtX2ELfBv0E6Jtsu7Lp0h4odQxQQ_Tcr8",
      authDomain: "blink-55e87.firebaseapp.com",
      projectId: "blink-55e87",
      storageBucket: "blink-55e87.appspot.com",
      messagingSenderId: "462123227973",
      appId: "1:462123227973:android:42f3ad588d595065a07c2c",
      measurementId: "G-NHCHEPSVLE"
    };


    const app = initializeApp(firebaseConfig);

    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    const messaging = getMessaging();

    onMessage(messaging, (payload) => {
      let notBody = payload.notification?.body || '{}';
      notBody = JSON.parse(notBody)

      store.dispatch(Actions.ADD_NOTIFICATION, notBody)
      
    });

    
    // getToken(messaging, { vapidKey: 'BNrjSlXofr5sKPNKnPbtSw8SFDaXRBsG9Dvn-4p_ATi3XY3JyA7sa549kTvzOBMmrOwCsN0N-X-cQ2QIa9a-Fzc' }).then((currentToken) => {
    //   if (currentToken) {
    //     // Send the token to your server and update the UI if necessary
    //     // console.log(currentToken);
        
    //     // ...
    //   } else {
    //     // Show permission request UI
    //     console.log('No registration token available. Request permission to generate one.');
    //     // ...
    //   }
    // }).catch((err) => {
    //   console.log('An error occurred while retrieving token. ', err);
    //   // ...
    // });


    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
