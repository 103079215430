enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  ALL_CATEGORIES = "allCategories",
  ONE_CATEGORY = "oneCategory",
  ADD_CATEGORY = "addCategory",
  EDIT_CATEGORY = "editCategory",
  DELETE_CATEGORY = "deleteCategory",
  CHANGE_SUBCAT_ALLOW = "changeSubcatAllow",
  CHANGE_SUBCAT_STATUS = "changeSubcatStatus",

  ALL_SUBCATEGORIES = "allSubcategories",
  ONE_SUBCATEGORY = "oneSubcategory",
  ADD_SUBCATEGORY = "addSubcategory",
  EDIT_SUBCATEGORY = "editSubcategory",

  ALL_LANGUAGES = "allLanguages",
  ONE_LANGUAGE = "oneLanguage",
  ADD_LANGUAGE = "addLanguage",
  DEL_LANGUAGE = "delLanguage",
  EDIT_LANGUAGE = "editLanguage",
  CHANGE_DEF_LANG = "changeDefaultLang",

  ALL_TAGS ="allTags",
  ADD_TAG = "addTag",
  EDIT_TAG = "editTag",
  DEL_TAG = "delTag",
  CHANGE_TAG_STATUS = "changeTagStatus",

  ALL_SOCIALS ="allSocials",
  ADD_SOCIAL = "addSocial",
  EDIT_SOCIAL = "editSocial",
  DEL_SOCIAL = "delSocial",
  CHANGE_SOCIAL_STATUS = "changeSocialStatus",

  ALL_PARAMETRS ="allParametrs",
  ADD_PARAMETR = "addParametr",
  EDIT_PARAMETR = "editParametr",
  DEL_PARAMETR = "delParametr",
  CHANGE_PARAMETR_STATUS = "changeParametrStatus",

  ALL_TRANSLATES ="allTranslates",
  ADD_TRANSLATE = "addTranslate",
  EDIT_TRANSLATE = "editTranslate",
  DEL_TRANSLATE = "delTranslate",
  CHANGE_TRANSLATE_STATUS = "changeTranslateStatus",

  ALL_USERS = "allUsers",
  ADD_USER = "addUser",
  CHANGE_USER_STATUS = "changeUserStatus",
  CHANGE_USER_ROLE = "changeUserRole",

  ALL_NEWS = "allNews",
  CHANGE_NEWS_STATUS = "changeNewsStatus",
  CHANGE_NEWS_PUBLISH = "changeNewsPublish",
  CHANGE_NEWS_BREAKING = "changeNewsBreaking",
  CHANGE_NEWS_SLIDER = "changeNewsSlider",



  ALL_TYPEES = "allTypes",


  ADD_NOTIFICATION = "addNotification",
  ALL_NOTIFICATIONS = "allNotifications",


  ALL_SECTIONS = "allSections",
  ADD_SECTION = "addSection",
  EDIT_SECTION = "editSection",
  CHANGE_SECTION_STATUS = "changeSectionStatus",

  ALL_QUESTIONS = "allQuestions",
  ADD_QUESTION = "addQuestion",
  EDIT_QUESTION = "editQuestion",
  CHANGE_QUESTION_STATUS = "changeQuestionStatus"


}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

  SET_CATEGORIES = "setCategories",
  SET_CATEGORY = "setCategory",
  SET_DEL_CATEGORY = "setDelCategory",

  SET_SUBCATEGORIES = "setSubcategories",
  SET_SUBCATEGORY = "setSubcategory",
  SET_EDIT_SUBCATEGORY = "setEditSubcategory",

  SET_LANGUAGES = "setLanguages",
  SET_LANGUAGE = "setLanguage",
  SET_DEL_LANGUAGE = "setDelLanguage",
  SET_EDIT_LANGUAGE = "setEditLanguage",

  SET_TAGS = "setTags",
  SET_TAG = "setTag",
  SET_EDIT_TAG = "setEditTag",
  SET_DEL_TAG = "setDelTag",

  SET_SOCIALS = "setSocials",
  SET_SOCIAL = "setSocial",
  SET_EDIT_SOCIAL = "setEditSocial",
  SET_DEL_SOCIAL = "setDelSocial",

  SET_PARAMETRS = "setParametrs",
  SET_PARAMETR = "setParametr",
  SET_EDIT_PARAMETR = "setEditParametr",
  SET_DEL_PARAMETR = "setDelParametr",

  SET_TRANSLATES = "setTranslates",
  SET_TRANSLATE = "setTranslate",
  SET_EDIT_TRANSLATE = "setEditTranslate",
  SET_DEL_TRANSLATE = "setDelTranslate",

  SET_USERS = "setUsers",
  SET_ONE_USER = "setOneUser",
  SET_EDIT_USER = "seteDITUser",

  SET_NEWS = "setNews",
  SET_EDIT_NEWS = "setEditNews",

  SET_TYPEES ="setTypees",

  SET_NOTIFICATIONS = "setNotifications",
  SET_NOTIFICATION = "setNotification",
  SET_NOTIFICATION_COUNT = "setNotificationCount",
  REMOVE_NOTIFICATION = "removeNotification",

  SET_SECTIONS = "setSections",
  SET_SECTION = "setSection",
  SET_EDIT_SECTION = "setEditSection",


  SET_QUESTIONS = "setQuetions",
  SET_QUESTION = "setQuetion",
  SET_EDIT_QUESTION = "setEditQuetion",
}

export { Actions, Mutations };
