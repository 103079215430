// import { Language } from './LanguageModule';
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Subcat{
    _id: string,
    title: string,
    slug: string,
}

export interface Section {
    _id: string;
    zone: string;
    slug: string;
    subcategory: Subcat[];
    translate?:string;
    status:number;
    createdAt: string;
    updatedAt: string
}

export interface SectionsInfo{
    sections: Section[];
    section: Section;
}


@Module
export default class SectionsModule extends VuexModule implements SectionsInfo{
    sections: Section[] = [];
    section = {} as Section;
    
    /**
   * Get current user object
   * @returns Categories
   */
  get alSections(): Section[] {
    return this.sections;
  }

    /**
   * Get current user object
   * @returns one category
   */
  get oneSection(): Section{
    return this.section;
  }

  @Mutation
  [Mutations.SET_SECTIONS](sections: Section[]){
    this.sections = sections
  }

  @Mutation
  [Mutations.SET_SECTION](section : Section){
    this.sections = [...this.sections, section]
  }

  @Mutation
  [Mutations.SET_EDIT_SECTION](section: Section){
    const index = this.sections.findIndex((item: Section)=> item._id == section._id)
    if(index > -1){
      this.sections[index] = section
    }
  }

//   @Mutation
//   [Mutations.SET_DEL_TAG](id: string){
//     const index = this.tags.findIndex((item: Tag)=> item._id == id)
//     if(index > -1){
//       this.tags.splice(index, 1)
//     }
    
//   }

  @Action
  [Actions.ALL_SECTIONS](pay){
    return ApiService.query("section", pay)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_SECTIONS, data.section)
       this.context.commit("setCount", data.count)
    })
  }


  @Action
  [Actions.CHANGE_SECTION_STATUS](pay){
    return ApiService.get("section/status/"+pay.item+"/"+pay.status)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_SECTION, data)
      })
  }

  @Action
  [Actions.ADD_SECTION](payload){
    return ApiService.post("/section", payload.value)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_SECTION, data)
      })
  }

  @Action
  [Actions.EDIT_SECTION](payload){
    return ApiService.put("/section", payload.value)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_SECTION, data)
      })
  }

//   @Action
//   [Actions.DEL_TAG](payload){
//     return ApiService.delete("/tag/"+ payload)
//     .then(({ data }) => {
//        this.context.commit(Mutations.SET_DEL_TAG, data)
//       })
//   }
}