// import { Language } from './LanguageModule';
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Tag {
    _id: string;
    name: string;
    subcategory: string;
}

export interface TagInfo{
    tags: Tag[];
    tag: Tag;
}


@Module
export default class TagsModule extends VuexModule implements TagInfo{
    tags: Tag[] = [];
    tag = {} as Tag;
    
    /**
   * Get current user object
   * @returns Categories
   */
  get alTags(): Tag[] {
    return this.tags;
  }

    /**
   * Get current user object
   * @returns one category
   */
  get oneTag(): Tag {
    return this.tag;
  }

  @Mutation
  [Mutations.SET_TAGS](tags: Tag[]){
    this.tags = tags
  }

  @Mutation

  [Mutations.SET_TAG](tag : Tag){
    this.tags = [...this.tags, tag]
  }

  @Mutation
  [Mutations.SET_EDIT_TAG](tag: Tag){
    const index = this.tags.findIndex((item: Tag)=> item._id == tag._id)
    if(index > -1){
      this.tags[index] = tag
    }
    
  }

  @Mutation
  [Mutations.SET_DEL_TAG](id: string){
    const index = this.tags.findIndex((item: Tag)=> item._id == id)
    if(index > -1){
      this.tags.splice(index, 1)
    }
    
  }

  @Action
  [Actions.ALL_TAGS](pay){
    return ApiService.query("tag", pay)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_TAGS, data.tags)
       this.context.commit('setCount', data.count)
      })
  }


  @Action
  [Actions.CHANGE_TAG_STATUS](pay){
    return ApiService.get("tag/status/"+pay.item+"/"+pay.status)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_TAG, data)
      })
  }

  @Action
  [Actions.ADD_TAG](payload){
    return ApiService.post("/tag", payload.value)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_TAG, data)
      })
  }

  @Action
  [Actions.EDIT_TAG](payload){
    return ApiService.put("/tag", payload.value)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_TAG, data)
      })
  }

  @Action
  [Actions.DEL_TAG](payload){
    return ApiService.delete("/tag/"+ payload)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_DEL_TAG, data)
      })
  }
}