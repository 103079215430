// import { Language } from './LanguageModule';
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Parametr {
    _id: string;
    title: string;
    icon: string;
    text: string;
}

export interface ParametrInfo{
    parametrs: Parametr[];
    parametr: Parametr;
}


@Module
export default class ParametrsModule extends VuexModule implements ParametrInfo{
    parametrs: Parametr[] = [];
    parametr = {} as Parametr;
    
    /**
   * Get current user object
   * @returns Categories
   */
  get alParametrs(): Parametr[] {
    return this.parametrs;
  }

    /**
   * Get current user object
   * @returns one category
   */
  get oneParametr(): Parametr {
    
    return this.parametr;
  }

  @Mutation
  [Mutations.SET_PARAMETRS](parametrs: Parametr[]){
    this.parametrs = parametrs
  }

  @Mutation

  [Mutations.SET_PARAMETR](social : Parametr){
    this.parametrs = [...this.parametrs, social]
  }

  @Mutation
  [Mutations.SET_EDIT_PARAMETR](social: Parametr){
    const index = this.parametrs.findIndex((item: Parametr)=> item._id == social._id)
    if(index > -1){
      this.parametrs[index] = social
    }
    
  }

  @Mutation
  [Mutations.SET_DEL_PARAMETR](id: string){
    const index = this.parametrs.findIndex((item: Parametr)=> item._id == id)
    if(index > -1){
      this.parametrs.splice(index, 1)
    }
    
  }

  @Action
  [Actions.ALL_PARAMETRS](pay){
    
    return ApiService.query("setting", pay)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_PARAMETRS, data.parametrs)
      })
  }


  @Action
  [Actions.ADD_PARAMETR](payload){
    console.log(payload,'payload');
    return ApiService.post("/setting", payload.value)
    .then(({ data }) => {
      console.log(data);
      
       this.context.commit(Mutations.SET_PARAMETR, data)
      })
  }

  @Action
  [Actions.CHANGE_PARAMETR_STATUS](pay){
    return ApiService.get("setting/status/"+pay.item+"/"+pay.status)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_PARAMETR, data)
      })
  }

  @Action
  [Actions.EDIT_PARAMETR](payload){
    return ApiService.put("/setting", payload.value)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_PARAMETR, data)
      })
  }

  @Action
  [Actions.DEL_PARAMETR](payload){
    return ApiService.delete("/setting/"+ payload)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_DEL_PARAMETR, data)
      })
  }
}