import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Subcategory {
    _id: string;
    title: string;
    slug: string;
    icon: string;
    text: string;
    meta_title: string;
    meta_keyword: string;
    meta_script: string;
    meta_desc: string;
    category: string;
}

export interface SubcategoryInfo{
    subcategories: Subcategory[];
    subcategory: Subcategory;
}


@Module
export default class SubcategoryModule extends VuexModule implements SubcategoryInfo{
    subcategories:Subcategory[] = [];
    subcategory = {} as Subcategory;
    

    /**
   * Get current user object
   * @returns Categories
   */
  get alSubategories(): Subcategory[] {
    return this.subcategories;
  }

    /**
   * Get current user object
   * @returns one category
   */
  get oneSubcategory(): Subcategory {
    return this.subcategory;
  }

  @Mutation
  [Mutations.SET_SUBCATEGORIES](subcategories){
    this.subcategories = subcategories
  }

  @Mutation
  [Mutations.SET_SUBCATEGORY](subcategory){
    this.subcategories = [...this.subcategories, subcategory]
  }

  @Mutation
  [Mutations.SET_EDIT_SUBCATEGORY](subcategory){
    const index = this.subcategories.findIndex((item: Subcategory)=> item._id == subcategory._id)
    if(index > -1){
      this.subcategories[index] = subcategory
    }
  }

  @Action
  [Actions.ALL_SUBCATEGORIES](pay){
    return ApiService.query("subcategory", pay)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_SUBCATEGORIES, data.subCategory)
       this.context.commit('setCount', data.count)
      })
  }


  @Action
  [Actions.ADD_SUBCATEGORY](payload){
    return ApiService.post("/subcategory", payload.value)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_SUBCATEGORY, data)
      })
  }

  @Action
  [Actions.CHANGE_SUBCAT_STATUS](pay){
    return ApiService.get("subcategory/status/"+pay.item+"/"+ pay.status)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_SUBCATEGORY, data)
      })
  }

  @Action
  [Actions.CHANGE_SUBCAT_ALLOW](payload){
    return ApiService.put("/subcategory", payload)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_SUBCATEGORY, data)
      })
  }

  @Action
  [Actions.EDIT_SUBCATEGORY](payload){
    console.log(payload, 'edit payload');
    
    return ApiService.put("/subcategory/", payload.value)
    .then(({ data }) => {
       
      //  this.context.commit(Mutations.SET_CATEGORY, data)
      })
  }
}