import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
// import { useStore } from "vuex";
// const store = useStore();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("@/views/apps/news/NewsListing.vue"),
      },
      {
        path: "/news/:id",
        name: "news-details",
        component: () => import("@/views/apps/news/NewsDetail.vue"),
      },
      {
        path: "/news/news-edit/:id",
        name: "news-edit",
        component: () => import("@/views/apps/news/NewsEdit.vue"),
      },
      {
        path: "/news/add-news",
        name: "add-news",
        component: () => import("@/views/apps/news/NewsAdd.vue"),
      },
      {
        path: "/categories",
        name: "categories",
        component: () => import("@/views/apps/categories/CategoryListing.vue"),
      },
      {
        path: "/categories/add-category",
        name: "add-category",
        component: () => import("@/views/apps/categories/CategoryAdd.vue"),
      },
      {
        path: "/categories/edit-category/:id",
        name: "edit-category",
        component: () => import("@/views/apps/categories/CategoryEdit.vue"),
      },
      {
        path: "/subcategories",
        name: "subcategories",
        component: () => import("@/views/apps/subcategories/SubcategoryListing.vue"),
      },
      {
        path: "/subcategories/edit-subcategory/:id",
        name: "edit-subcategory",
        component: () => import("@/views/apps/subcategories/SubcategoryEdit.vue"),
      },
      {
        path: "/subcategories/add-subcategory",
        name: "add-subcategory",
        component: () => import("@/views/apps/subcategories/SubcategoryAdd.vue"),
      },
      {
        path: "/languages",
        name: "languages",
        component: () => import("@/views/apps/languages/LanguageList.vue"),
      },
      {
        path: "/tags",
        name: "tags",
        component: () => import("@/views/apps/tags/TagsListing.vue"),
      },
      {
        path: "/social",
        name: "social",
        component: () => import("@/views/apps/social/SocialListing.vue"),
      },
      {
        path: "/parametrs",
        name: "parametrs",
        component: () => import("@/views/apps/parametrs/ParametrListing.vue"),
      },
      {
        path: "/translate",
        name: "translate",
        component: () => import("@/views/apps/translate/TranslateListing.vue"),
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/apps/users/UserListing.vue"),
      },
      {
        path: "/users/:id",
        name: "user-detail",
        component: () => import("@/views/apps/users/UserDetail.vue"),
      },
      {
        path: "/sections",
        name: "sections",
        component: () => import("@/views/apps/sections/SectionListing.vue"),
      },
      {
        path: "/questions",
        name: "questions",
        component: () => import("@/views/apps/questions/QuestionListing.vue"),
      },
      {
        path: "/notifications/:id",
        name: "notifications",
        component: () => import("@/views/apps/notifications/NotificationDetail.vue"),
      },
      {
        path: "/pages/profile/overview",
        name: "overview",
        component: () => import("@/views/crafted/pages/profile/Overview.vue"),
      },
    ],
  },
  {
    path: "/auth",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/editor",
    component: () => import("@/layout/EditerLayout.vue"),
    children:[
      {
        path: "news",
        name: "editor-news",
        component: () => import("@/views/apps/news/NewsListing.vue"),
      },
      {
        path: "news/:id",
        name: "editor-news-details",
        component: () => import("@/views/apps/news/NewsDetail.vue"),
      },
      {
        path: "news/add-news",
        name: "editor-add-news",
        component: () => import("@/views/apps/news/NewsAdd.vue"),
      },
      {
        path: "news/news-edit/:id",
        name: "editor-news-edit",
        component: () => import("@/views/apps/news/NewsEdit.vue"),
      },
    ]
  },
  
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  // {
  //   path: "/:pathMatch(.*)*",
  //   redirect: "/404",
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // if(store.getters.currentUser.role == 'editor'){
  //   router.push('editor')
  // }else if (store.getters.currentUser.role == 'admin') {
  //   router.push('/')
  // }else{
  //   router.push('sign-in')
  // }
 
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
