// import { Language } from './LanguageModule';
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Social {
    _id: string;
    name: string;
    icon: string;
    link: string;
}

export interface SocialInfo{
    socials: Social[];
    social: Social;
}


@Module
export default class SocialsModule extends VuexModule implements SocialInfo{
    socials: Social[] = [];
    social = {} as Social;
    
    /**
   * Get current user object
   * @returns Categories
   */
  get alSocials(): Social[] {
    return this.socials;
  }

    /**
   * Get current user object
   * @returns one category
   */
  get oneSocial(): Social {
    
    return this.social;
  }

  @Mutation
  [Mutations.SET_SOCIALS](socials: Social[]){
    this.socials = socials
  }

  @Mutation

  [Mutations.SET_SOCIAL](social : Social){
    this.socials = [...this.socials, social]
  }

  @Mutation
  [Mutations.SET_EDIT_SOCIAL](social: Social){
    const index = this.socials.findIndex((item: Social)=> item._id == social._id)
    if(index > -1){
      this.socials[index] = social
    }
    
  }

  @Mutation
  [Mutations.SET_DEL_SOCIAL](id: string){
    const index = this.socials.findIndex((item: Social)=> item._id == id)
    if(index > -1){
      this.socials.splice(index, 1)
    }
    
  }

  @Action
  [Actions.ALL_SOCIALS](pay){
    console.log(pay)
    return ApiService.query("social", pay)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_SOCIALS, data.social)
      })
  }


  @Action
  [Actions.ADD_SOCIAL](payload){
    return ApiService.post("/social", payload.value)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_SOCIAL, data)
      })
  }

  @Action
  [Actions.CHANGE_SOCIAL_STATUS](pay){
    return ApiService.get("social/status/"+pay.item+"/"+pay.status)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_SOCIAL, data)
      })
  }

  @Action
  [Actions.EDIT_SOCIAL](payload){
    return ApiService.put("/social", payload.value)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_SOCIAL, data)
      })
  }

  @Action
  [Actions.DEL_SOCIAL](payload){
    return ApiService.delete("/social/"+ payload)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_DEL_SOCIAL, data)
      })
  }
}