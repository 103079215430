
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";


export interface Author{
    _id: string;
    name: string;
}

export interface Subcategory{
    _id: string;
    title: string;
}

export interface Tag{
    _id: string;
    name: string;
}
export interface Parametr{
    _id: string;
    title: string;
}

export interface News {
    _id: string;
    author: Author;
    subcategory: Subcategory
    title: string;
    text: string;
    like: number;
    dislike: number;
    tags: Tag[];
    parametrs: Parametr[];
    img: string;
    confirmation: boolean;
    views: number;
    publishtime: string;
    publishpermission: boolean;
    allow_comment: boolean;
    slug: string;
    status: number;
}

export interface NewsInfo{
    newses: News[];
    news: News;
}


@Module
export default class NewsModule extends VuexModule implements NewsInfo{
    newses: News[] = [];
    news = {} as News;
    
    /**
   * Get current user object
   * @returns Categories
   */
  get alNews(): News[] {
    return this.newses;
  }

  

  @Mutation
  [Mutations.SET_NEWS](newses: News[]){
    this.newses = newses
  }

  // @Mutation

  // [Mutations.SET_ONE_USER](users : User){
  //   this.users = [...this.users, users]
  // }

  @Mutation
  [Mutations.SET_EDIT_NEWS](news: News){
    const index = this.newses.findIndex((item: News)=> item._id == news._id)
    if(index > -1){
      this.newses[index] = news
    } 
  }

//   @Mutation
//   [Mutations.SET_DEL_TAG](id: string){
//     const index = this.tags.findIndex((item: Tag)=> item._id == id)
//     if(index > -1){
//       this.tags.splice(index, 1)
//     }
    
//   }

  @Action
  [Actions.ALL_NEWS](pay){
    return ApiService.query("news", pay)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_NEWS, data.news);
       this.context.commit('setCount', data.count)
      })
  }


  // @Action
  // [Actions.CHANGE_USER_ROLE](pay){
  //   return ApiService.put("user/role/" + pay.item, pay.role)
  //   .then(({ data }) => {
     
      
  //      this.context.commit(Mutations.SET_EDIT_USER, data.user)
  //     })
  // }

  @Action
  [Actions.CHANGE_NEWS_STATUS](pay){
    return ApiService.get("news/status/"+pay.item+"/"+pay.status)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_NEWS, data)
      })
  }


  @Action
  [Actions.CHANGE_NEWS_BREAKING](pay){
    return ApiService.get("news/breaking/"+pay.item+"/"+pay.status)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_NEWS, data)
      })
  }
  @Action
  [Actions.CHANGE_NEWS_SLIDER](pay){
    return ApiService.get("news/slider/"+pay.item+"/"+pay.status)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_NEWS, data)
      })
  }


  @Action
  [Actions.CHANGE_NEWS_PUBLISH](pay){
    return ApiService.post("news/allow/"+pay.item+"/"+pay.val, pay.data)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_EDIT_NEWS, data)
      })
  }

  // @Action
  // [Actions.ADD_USER](payload){
  //   return ApiService.post("/tag", payload.value)
  //   .then(({ data }) => {
  //      this.context.commit(Mutations.SET_TAG, data)
  //     })
  // }

  // @Action
  // [Actions.EDIT_TAG](payload){
  //   console.log(payload.value, 'payload');
    
  //   return ApiService.put("/tag", payload.value)
  //   .then(({ data }) => {
  //     console.log(data, 'returned data')
  //      this.context.commit(Mutations.SET_EDIT_TAG, data)
  //     })
  // }

  // @Action
  // [Actions.DEL_TAG](payload){
  //   return ApiService.delete("/tag/"+ payload)
  //   .then(({ data }) => {
  //      this.context.commit(Mutations.SET_DEL_TAG, data)
  //     })
  // }
}