// import { Language } from './LanguageModule';
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Typee {
    _id: string;
    title: string;
    slug: string;
}

export interface TypeeInfo{
    typees: Typee[];
    typee: Typee;
}


@Module
export default class TypeesModule extends VuexModule implements TypeeInfo{
    typees: Typee[] = [];
    typee = {} as Typee;
    
    /**
   * Get current user object
   * @returns Categories
   */
  get alTypees(): Typee[] {
    return this.typees;
  }

    /**
   * Get current user object
   * @returns one category
   */
//   get oneTag(): Tag {
//     return this.tag;
//   }

  @Mutation
  [Mutations.SET_TYPEES](typees: Typee[]){
    this.typees = typees
  }

//   @Mutation
//   [Mutations.SET_TAG](tag : Tag){
//     this.tags = [...this.tags, tag]
//   }

//   @Mutation
//   [Mutations.SET_EDIT_TAG](tag: Tag){
//     const index = this.tags.findIndex((item: Tag)=> item._id == tag._id)
//     if(index > -1){
//       this.tags[index] = tag
//     }
    
//   }

//   @Mutation
//   [Mutations.SET_DEL_TAG](id: string){
//     const index = this.tags.findIndex((item: Tag)=> item._id == id)
//     if(index > -1){
//       this.tags.splice(index, 1)
//     }
    
//   }

  @Action
  [Actions.ALL_TYPEES](pay){
    return ApiService.query("type/all", pay)
    .then(({ data }) => {
       this.context.commit(Mutations.SET_TYPEES, data)
      })
  }


//   @Action
//   [Actions.CHANGE_TAG_STATUS](pay){
//     return ApiService.get("tag/status/"+pay.item+"/"+pay.status)
//     .then(({ data }) => {
//        this.context.commit(Mutations.SET_EDIT_TAG, data)
//       })
//   }

//   @Action
//   [Actions.ADD_TAG](payload){
//     return ApiService.post("/tag", payload.value)
//     .then(({ data }) => {
//        this.context.commit(Mutations.SET_TAG, data)
//       })
//   }

//   @Action
//   [Actions.EDIT_TAG](payload){
//     console.log(payload.value, 'payload');
    
//     return ApiService.put("/tag", payload.value)
//     .then(({ data }) => {
//       console.log(data, 'returned data')
//        this.context.commit(Mutations.SET_EDIT_TAG, data)
//       })
//   }

//   @Action
//   [Actions.DEL_TAG](payload){
//     return ApiService.delete("/tag/"+ payload)
//     .then(({ data }) => {
//        this.context.commit(Mutations.SET_DEL_TAG, data)
//       })
//   }
}